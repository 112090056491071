<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="getCurrentDate">
          今日
        </v-btn>
      </div>
      <div class="mx-2">
        <v-row dense>
          <v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="prevDay"
              elevation="0"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></v-col
          ><v-col class="mx-n4">
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="menu = !menu"
              elevation="0"
            >
              {{ formattedDate }}
            </v-btn>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
              ><template v-slot:activator="{ on }">
                <div v-on="on"></div>
              </template>
              <v-date-picker
                v-model="formattedDate"
                :value="selectedDate"
                @input="menu = false"
              ></v-date-picker> </v-menu></v-col
          ><v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="nextDay"
              elevation="0"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn></v-col
          ></v-row
        >
      </div>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="careLogs"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:item.finished="{ item }">
          <v-icon large color="green" class="mr-2">
            mdi-file-document-check
          </v-icon>
        </template>
        <template v-slot:item.viewRecord="{ item }">
          <v-icon large class="mr-2" @click="viewRecord(item)">
            mdi-eye
          </v-icon>
        </template>
        <template v-slot:item.addRecord="{ item }">
          <v-icon large @click="addRecord(item)"> mdi-pencil </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="add_record_dialog" persistent fullscreen>
      <v-toolbar dark color="#025464">
        <v-btn icon dark @click="add_record_dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>填寫新日誌 - Name</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row dense v-for="(item, key) in contents" :key="key">
                <v-col cols="12">
                  <span class="font-weight-bold black--text">{{
                    item.question
                  }}</span></v-col
                >
                <v-col cols="12">
                  <v-radio-group dense row v-model="item.answer" class="ma-2">
                    <v-radio
                      v-for="option in item.options"
                      :key="option"
                      :label="option"
                      :value="option"
                    ></v-radio></v-radio-group
                ></v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="mx-4"
            color="red darken-1"
            text
            @click="add_record_dialog = false"
            >取消</v-btn
          >
          <v-btn class="mx-4" color="blue darken-1" text @click="submitForm"
            >送出</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "姓名",
          align: "start",
          sortable: false,
          value: "name",
          align: "center",
        },
        {
          text: "填寫狀態",
          value: "finished",
          align: "center",
        },
        {
          text: "查看紀錄",
          value: "viewRecord",
          align: "center",
        },
        {
          text: "填寫新日誌",
          value: "addRecord",
          align: "center",
        },
      ],
      careLogs: [],
      menu: false,
      selectedDate: new Date(),
      add_record_dialog: false,
      contents: {},
      staff_id: "",
      case_num: "",
    };
  },
  methods: {
    async getList() {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/journal/get_all_record?date=${this.formattedDate}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.careLogs = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getRecord(case_num) {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/journal/search_record?case_num=${case_num}&date=${this.formattedDate}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.contents = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async makeRecord(case_num) {
      const axios = require("axios");
      let data = JSON.stringify({
        case_num: case_num,
        employee_id: this.staff_id,
        date: this.formattedDate,
        ...this.contents,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/journal/make_record",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        console.log(`${res.data.status}, ${res.data.data}`);
      } catch (error) {
        console.log(error);
      }
    },
    viewRecord(item) {
      console.log(`查看 ${item.case_num} 紀錄`);
    },
    addRecord(item) {
      this.case_num = item.case_num;
      this.getRecord(this.case_num);
      this.add_record_dialog = true;
    },
    getCurrentDate() {
      this.selectedDate = new Date();
    },
    prevDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() - 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    nextDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    submitForm() {
      this.makeRecord(this.case_num);
      this.add_record_dialog = false;
    },
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear();
        const month = this.selectedDate.getMonth() + 1;
        const day = this.selectedDate.getDate();
        return `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month, day] = newValue.split("-").map(Number);
        this.selectedDate = new Date(year, month - 1, day);
      },
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    selectedDate() {
      this.getList();
    },
  },
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>
<style scoped>
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 75vw;
}

.table {
  width: 100%;
}
</style>
