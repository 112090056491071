<template>
  <div class="pa-2 my-auto">
    <!-- <v-row no-gutters>
      <v-icon x-large color="black" class="py-2">mdi-clipboard-account</v-icon>
      <div class="pa-2 text-h4 font-weight-bold">機構人員列表</div>
    </v-row> -->
    <v-row dense>
      <v-col
        class="pa-2"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(resident, index) in residents"
        :key="index"
        ><v-card
          hover
          class="pa-1"
          :style="resident.fall ? 'border: 2px solid red' : ''"
          @click="resident.fall ? confirmFall(resident.group) : ''" 
          ><v-card-title class="pa-2"
            ><span class="card-title black--text">
              {{ resident.group }}
            </span></v-card-title
          >
          <v-row dense>
            <v-col
              class="px-2 py-4"
              cols="6"
              v-for="(role, index) in resident.cases"
              :key="index"
            >
              <v-card
                :class="cardClass(role.status)"
                hover
                @click="getDetails(role.name, IDs[index])"
              >
                <v-card-title
                  class="pa-0 pa-sm-2 justify-center text-h6 font-weight-bold"
                >
                  <span class="card-title">
                    {{ role.name }}
                  </span>
                  <v-icon
                    class="lights"
                    :style="role.status ? 'color:#008055' : ''"
                    @click.stop="manualDialog(role.name, role.status, index)"
                  >
                    mdi-circle-slice-8
                  </v-icon>
                </v-card-title>
                <v-card-actions class="pa-0">
                  <v-row no-gutters align="center">
                    <v-col>
                      <div class="text-center">
                        <v-icon
                          :style="!role.loss ? 'color:#53BF9D' : 'color:red'"
                        >
                          {{
                            !role.loss ? "mdi-emoticon-outline" : "mdi-alert"
                          }}
                        </v-icon>
                      </div>
                      <div class="text-center text-caption">走失警示</div>
                    </v-col>
                    <v-col
                      v-if="role.loss"
                      @click.stop="checkLoss(role, index)"
                    >
                      <div class="text-center">
                        <v-icon color="#FFC54D">mdi-eye</v-icon>
                      </div>
                      <div class="text-center text-caption">查看現場</div>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-card-text
                  class="px-0 py-2 justify-center text-subtitle-1 font-weight-bold black--text"
                >
                  <v-row no-gutters class="text-center">
                    <v-col cols="6">
                      <v-row no-gutters justify="center" align="center">
                        <v-icon color="#F94C66">mdi-heart</v-icon>
                        <div v-if="role.heart_rate != ''">{{ role.heart_rate }} bpm</div>
                        <div v-else>無資料</div>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row no-gutters justify="center" align="center">
                        <v-icon color="#BD4291">mdi-walk</v-icon>
                        <div v-if="role.sixmwt != ''">{{ role.sixmwt }}m/s</div>
                        <div v-else>無資料</div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row></v-card
        ></v-col
      >
    </v-row>
    <!-- <v-row no-gutters>
      <v-icon x-large color="blue" class="py-2">mdi-bullhorn</v-icon>
      <div class="pa-2 text-h4 font-weight-bold blue--text">異常事件偵測</div>
    </v-row>
    <v-row dense>
      <v-col
        class="pa-2 py-4"
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="(fell, index) in fall"
        :key="index"
      >
        <v-card :style="fell.confirm ? '' : 'border: 2px solid red'">
          <v-card-title
            class="pa-0 pa-sm-2 justify-center text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold"
          >
            {{ fell.message }}
          </v-card-title>
          <v-card-actions>
            <v-row no-gutters>
              <v-col>
                <div class="text-center text-caption text-sm-body-1">
                  危險警示
                </div>
                <div class="text-center">
                  <v-icon
                    x-large
                    :style="fell.confirm ? 'color:green' : 'color:red'"
                  >
                    {{ fell.confirm ? "mdi-emoticon-outline" : "mdi-alert" }}
                  </v-icon>
                </div>
              </v-col>
              <v-col v-if="!fell.confirm">
                <div class="text-center text-caption text-sm-body-1">
                  確認查看
                </div>
                <div class="text-center">
                  <v-btn icon @click="checkFall(index, fell.id)">
                    <v-icon x-large color="light-green">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row> -->

    <v-dialog v-model="imageLoss" width="75vw">
      <v-card class="pa-2">
        <v-card-text class="pa-2">
          <v-img contain height="70vh" :src="lossURL" />
        </v-card-text>
        <v-card-actions class="pa-2 justify-center">
          <v-btn text color="blue" @click="imageLoss = false">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="manual" max-width="300px">
      <v-card class="pa-2">
        <v-card-title class="pa-2 justify-center">
          將{{ currentName }}設為已簽到?
        </v-card-title>
        <v-card-actions class="pa-2 justify-center">
          <v-btn text color="red" @click="manual = false">取消</v-btn>
          <v-spacer />
          <v-btn
            text
            color="blue"
            @click="manualstatus(currentName, currentIndex, currentStatus)"
          >
            確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar centered v-model="snackbar" timeout="750" :color="snackColor">
      <div class="font-weight-bold text-center" style="color: #f6f1f1">
        <span>{{ check_response }}</span>
      </div>
      <!-- <template v-slot:action="{ attrs }">
        <v-btn color="#AFD3E2" text v-bind="attrs" @click="snackbar = false">
          關閉
        </v-btn>
      </template> -->
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import moment from "moment";
export default {
  data() {
    return {
      alert: false,
      isAlert: false,
      manual: false,
      imageLoss: false,
      check_response: "",
      currentName: "",
      currentIndex: "",
      currentStatus: "",
      hr: [],
      IDs: [
        "k87j6e7c",
        "lhq7k3id",
        "lhq7oyab",
        "ljb3m0fp",
        "l0m0twvp",
        "l0m0roe0",
        "lhpqjzva",
        "li3z9fac",
        "leocq26v",
        "ldo7lp3a",
        "l8mrvsve",
        "kfp1r5hi",
        "l0g7ah5u",
        "lhq7z590",
        "lhq2a592",
        "ld1ce6qy",
        "le9hs1d7",
        "lfamgnyi",
      ],
      loss: "",
      fall: "",
      lossURL: "",
      snackbar: false,
      snackColor: "",
      state: "",
      interval: null,
      // cases: [
      //   {
      //     name: "吳O東",
      //     sixmwt: "",
      //     heart_rate: "",
      //     loss: false,
      //     status: false,
      //   },
      //   {
      //     name: "謝O廷",
      //     sixmwt: "",
      //     heart_rate: "",
      //     loss: false,
      //     status: false,
      //   },
      //   {
      //     name: "蘇O正",
      //     sixmwt: "",
      //     heart_rate: "",
      //     loss: false,
      //     status: false,
      //   },
      //   {
      //     name: "王O敏",
      //     sixmwt: "",
      //     heart_rate: "",
      //     loss: false,
      //     status: false,
      //   },
      //   {
      //     name: "吳O定",
      //     sixmwt: "",
      //     heart_rate: "",
      //     loss: false,
      //     status: false,
      //   },
      //   {
      //     name: "李O燿",
      //     sixmwt: "",
      //     heart_rate: "",
      //     loss: false,
      //     status: false,
      //   },
      //   {
      //     name: "曾O維",
      //     sixmwt: "",
      //     heart_rate: "",
      //     loss: false,
      //     status: false,
      //   },
      // ],
      residents: [
        {
          group: "Room201",
          fall: false,
          cases: [
            {
              name: "吳O東",
              sixmwt: "",
              heart_rate: "",
              loss: false,
              status: false,
            },
            {
              name: "謝O廷",
              sixmwt: "",
              heart_rate: "",
              loss: false,
              status: false,
            },
            {
              name: "蘇O正",
              sixmwt: "",
              heart_rate: "",
              loss: false,
              status: false,
            },
            {
              name: "王O敏",
              sixmwt: "",
              heart_rate: "",
              loss: false,
              status: false,
            },
          ],
        },
        {
          group: "Room202",
          fall: false,
          cases: [
            {
              name: "吳O定",
              sixmwt: "",
              heart_rate: "",
              loss: false,
              status: false,
            },
            {
              name: "李O燿",
              sixmwt: "",
              heart_rate: "",
              loss: false,
              status: false,
            },
            {
              name: "曾O維",
              sixmwt: "",
              heart_rate: "",
              loss: false,
              status: false,
            },
          ],
        },
      ],
    };
  },
  methods: {
    cardClass(disable) {
      return disable ? "enabled-card" : "disabled-card";
    },
    getDetails(name, id) {
      this.$router.push("/services/" + id);
    },
    manualDialog(name, status, index) {
      this.manual = true;
      this.currentName = name;
      this.currentStatus = status;
      this.currentIndex = index;
    },
    manualstatus(name, index, status) {
      // this.sortCases();
      let currentTime = moment().format("YYYY/MM/DD HH:mm");
      console.log(currentTime);
      if (status) {
        this.state = "check_out";
        this.cases[index].status = false;
        this.check_response = name + "已完成簽退";
        this.snackColor = "#943c14";
        this.snackbar = true;
      } else {
        this.state = "check_in";
        this.cases[index].status = true;
        this.check_response = name + "已完成簽到";
        this.snackColor = "#146C94";
        this.snackbar = true;
      }
      const data = qs.stringify({
        name: name,
        datetime: currentTime,
        state: this.state,
      });
      axios
        .post("/api/attendance/manual_check", data)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log("failed", error);
        });
      this.manual = false;
    },
    sortCases() {
      this.cases.sort((a, b) => {
        if (a.status && !b.status) {
          return -1; // a 在 b 前面
        } else if (!a.status && b.status) {
          return 1; // b 在 a 前面
        } else {
          return 0; // 保持原本的順序
        }
      });
    },
    async getHeartRate() {
      const axios = require("axios");
      for (let i = 0; i < this.IDs.length; i++) {
        let id = this.IDs[i];
        let config = {
          method: "get",
          url: "https://ntu-med-god.ml/api/getLatestHeartRate?id=" + id,
          header: {},
        };
        try {
          let res = await axios(config);
          this.cases[i].heart_rate = res.data[0].value;
          this.hr.push(res.data[0].value);
          // console.log(this.hr);
        } catch (error) {
          console.log(error);
          this.hr.push("無");
          this.cases[i].heart_rate = "無";
        }
      }
      // console.log(this.hr);
      // console.log(this.cases);
    },
    checkLoss(role, index) {
      this.imageLoss = true;
      role.loss = false;
      const data = qs.stringify({ name: role.name });
      axios
        .post("/api/attendance/confirm_loss", data)
        .then((res) => {
          this.lossURL = res.data.data;
        })
        .catch((error) => {
          console.log("failed", error);
        });
    },
    startPolling() {
      this.pollMessages();
    },
    pollMessages() {
      axios
        .get("/api/attendance/list_people")
        .then((res) => {
          this.residents = res.data.residents;
          // this.sortCases();
          console.log(this.residents);
          this.interval = setTimeout(() => {
            this.pollMessages();
          }, 3000);
        })
        .catch((error) => {
          console.error("failed", error);
          this.interval = setTimeout(() => {
            this.pollMessages();
          }, 3000);
        });
    },
    confirmFall(group){
      const data = qs.stringify({ group: group });
      axios
        .post("/api/violation/confirm_violation/", data)
        .catch((error) => {
          console.log("failed", error);
        });
    }
  },
  mounted() {
    // this.getHeartRate();
    this.startPolling();
  },
  beforeDestroy() {
    clearTimeout(this.interval);
  },
};
</script>
<style scoped>
.disabled-card {
  background-color: #a6a6a6;
  opacity: 0.5;
}

.enabled-card {
  background-color: #f9f9e1;
}

.card-title {
  color: #0079ff;
}
.lights {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
